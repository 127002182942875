<template>
  <div class="col-12">
    
  <b-modal id="sample-image" title="CSV File Upload Checks Summary">
    <img src="/img/sample-csv.png" class='w-100' alt="" srcset="" >
  </b-modal>
  <b-modal id="import-result"
   size='lg'
   title="CSV File Upload"
   cancel-title="Reupload"
   ok-title="Generate List"
   @ok="generateRoot"
    >
    <h2>Summary</h2>
    <h5 class='text-center'>Errors</h5>
    <p class="mb-0" v-for='(error, index) in errors' :key='index'>{{error}}</p>
    <h5 class='text-center'>Wallet Skipped</h5>
    <p class="mb-0" v-for='(error, index) in skipped' :key='index'>{{error}}</p>
    <button class="btn btn-primary" @click='showDetails = !showDetails'> 
    {{(showDetails) ? "Hide Details" : "Show Added Wallets"}}
    </button>
     <table class='table' v-if='showDetails'>
      <thead>
        <tr>
          <th>Wallet</th>
          <th>Value</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for='(wallet, key) in csvData' :key='key'>
          <td>
            {{key}}
          </td>
          <td>
            {{parseInt(csvData[key],16)/10**app.DECIMALS}} {{app.symbol}}
            
          </td>
        </tr>
      </tbody>
    </table> 
    
    
  </b-modal>
    <div class="row">
    <div class="col-sm-12 col-md-12" v-if='currentStep == 0'>
          <h5 class='text-center'
          :class='[($root.d) ? "text-whites" : "text-darks"]'
          >Please provide below Information<br>
          <!-- Note:Provided Information will not be changed -->
          </h5>
          
          <div class="input-group">
            <label for="">Service Title</label>
            <input type="text" name="" id="" v-model="appTitle" class="form-control">
          </div>

          <div class="input-group">
            <label for="">Service Type</label>
            <select name="" id="" class="form-control" v-model="slService">
            <option value="" v-for="service in services" :key="service._id">{{service.name}}</option>
            </select>
          </div>

          <div class="form-group">
            <label :class='[($root.d) ? "text-whites" : "text-darks"]' for="Chain">Select Network</label>
            <select name="" id="" v-model='selectedChain' class="form-control">
              <option v-for='chain in chains' :key='chain.chainId' :value="chain">
                {{chain.name}}
              </option>
            </select>
          </div>

          <div class="form-group" v-if='selectedChain'>
            <label :class='[($root.d) ? "text-whites" : "text-darks"]' for="">Token</label>
             <input type="text" name="" id="" @blur="validateWallet" v-model='selectedToken' class="form-control">
             <span class="text-danger" v-if="loadingBQ">Please Wait, Fetching Token Contract Details</span>
          </div>

          <div class="form-group" v-if='selectedChain'>
            <label :class='[($root.d) ? "text-whites" : "text-darks"]' for="">Decimals</label>
            <input type='number' class="form-control" :disabled='!manualInput' v-model='selectedDecimal' >
          </div>

          <div class="form-group" v-if='selectedChain'>
            <label :class='[($root.d) ? "text-whites" : "text-darks"]' for="">Token Symbol</label>
            <input type='text' minlength="3" maxlength="5" class="form-control" :disabled='!manualInput' v-model='selectedSymbol' >
          </div>

          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text shadow-c d-inline-block"
              :class='[($root.d) ? "bg-whites text-whites" : "bg-darks text-darks"]'
                style='width:135px;border-radius:22px;' id="">Owner</span>
            </div>
            <span  
            :class='[($root.d) ? "bg-whites text-whites" : "bg-darks text-darks"]'
              class="form-control inp-plain"> {{ (selectedAccount) ? selectedAccount : "" }} </span>
            <div class="input-group-append">
              <button class="btn btn-primary" v-if="selectedAccount" disabled> Connected </button>
              <button class="btn btn-primary" v-else @click="connect()"> Connect Wallet </button>
            </div>
          </div>
          <button class="btn btn-success" @click="saveAppChanges">Save Changes</button>
          
          <!-- </div> -->
          
        </div>
    <!-- </div>   -->
        <div class="col-sm-12 col-md-12" v-if='currentStep == 1'>
          <h2 class='text-center'
          :class='[($root.d) ? "text-whites" : "text-darks"]'>
            Wellcome Welcome to the Claim Service Setup
          </h2>
          
          <div>
            <h3 class='text-center'
          :class='[($root.d) ? "text-whites" : "text-darks"]'>
            Please Upload CSV File <span class="btn btn-link" @click='loadSampleImage'>View Sample</span>
          </h3>
            <div class="custom-file">
              <input type="file" class="custom-file-input" accept=".csv" @change='handleFile' id="validatedCustomFile" required>
              <label class="custom-file-label" for="validatedCustomFile">Choose file...</label>
              <div class="invalid-feedback">Example invalid custom file feedback</div>
            </div>
            <button class="btn btn-danger mt-2 ml-2" @click='backToApps'>
            Back to Services
          </button>
          </div>
        </div>
        
        <div class="col-sm-12 col-md-12" v-if='currentStep == 2'>
          <h5 class='text-center'
          :class='[($root.d) ? "text-whites" : "text-darks"]'>
            Contract is Ready For Deployment Now <br>
            Please Confirm Below Details and click Deploy
          </h5>
          <p :class='[($root.d) ? "text-whites" : "text-darks"]' v-if='app.selectedChain !== null && app.selectedChain !== undefined'>
            Network Chain Selected: 
            <b class="float-right"> {{app.selectedChain.name}} </b>
          </p>
          <p :class='[($root.d) ? "text-whites" : "text-darks"]' v-else>
            <b class="text-danger">PLEASE SELECT NETWORK</b> 
            <!-- <b class="float-right"> {{app.selectedChain.name}} </b> -->
          </p>
          <p :class='[($root.d) ? "text-whites" : "text-darks"]'>
            Token Contract: 
            <b class="float-right"> {{app.TOKEN}} </b>
          </p>
          
          <p :class='[($root.d) ? "text-whites" : "text-darks"]'>
            Token Symbol: 
            <b class="float-right"> {{app.symbol}} </b>
          </p>
          <p :class='[($root.d) ? "text-whites" : "text-darks"]'>
            Token Deciamls: 
            <b class="float-right"> {{app.DECIMALS}} </b>
          </p>
          <p :class='[($root.d) ? "text-whites" : "text-darks"]'>
            Total Claimable Wallets: 
            <b class="float-right" v-if="totalClaimWallets"> {{totalClaimWallets}} </b>
            <b class="floa-right text-danger" v-else> List not generated </b>
          </p>
          <p :class='[($root.d) ? "text-whites" : "text-darks"]'>
            Total Claimable Amount: 
            <b class="float-right" v-if="totalClaimBalance"> {{totalClaimBalance}} </b>
            <b class="floa-right text-danger" v-else> List not generated </b>
          </p>
          <p :class='[($root.d) ? "text-whites" : "text-darks"]'>
            Owner/Admin of the Distribution Contract: 
            <b class="float-right" v-if="app.owner"> {{app.owner}} </b>
            <b class="float-right" v-else> NOT SET </b>
          </p>
          <div class="p-2" v-if="$store.state.txProgress">
            <p class="text-success"> Transaction is in Progress
              <span v-if="$store.state.txHash.length" class='btn btn-link px-0 float-right' @click='copy($store.state.txHash)'>&#x2398;</span>
            </p>
        </div>
          <!-- <button class="btn btn-primary mt-3 float-left" @click='stepBack(1)'> Back </button> -->
          <div class="p-2" v-if='app.selectedChain !== null && app.selectedChain !== undefined && totalClaimBalance'>
            <button class="btn btn-primary mt-3 mx-2 float-right" v-if="selectedAccount" disabled> Connected </button>
              <button class="btn btn-primary mt-3 mx-2 float-right" v-else @click="connect()"> Connect Wallet </button>
              <button class="btn btn-primary mt-3 mx-2 float-right" @click='confirmDeploy'> Confirm Details & Deploy </button>
          </div>
          
        </div>
        <div class="col-sm-12 col-md-12" v-if='currentStep == 3'>
          <!-- <h5 class='text-center btn btn-link'
          :class='[($root.d) ? "text-whites" : "text-darks"]' @click='goToScan(app.CONTRACT, app.selectedChain.chainId)'>
          </h5> -->
          <h5 class='text-center'
          :class='[($root.d) ? "text-whites" : "text-darks"]'>
            Please select Start and End date or leave blank for no expiry period
          </h5>
           <div>
          <label :class='[($root.d) ? "text-whites" : "text-darks"]' for="start-date">Choose a start date</label>
          <b-form-datepicker id="start-date" v-model="startDate" class="mb-2"></b-form-datepicker>
          <label :class='[($root.d) ? "text-whites" : "text-darks"]' for="end-date">
            Choose a end date(Please choose any future date greater then Start Date)
            </label>
          <b-form-datepicker id="end-date" v-model="endDate" class="mb-2"></b-form-datepicker>
          <button class="btn btn-primary" @click='saveTimeChanges'>
            Save Changes
          </button>
          <!-- <button class="btn btn-danger ml-2" @click='backToApps'>
            Back to Services
          </button> -->
        </div>

          </div>
        <div class="col-sm-12 col-md-12" v-if='currentStep == 4'>
          <h5 class='text-center'
          :class='[($root.d) ? "text-whites" : "text-darks"]'>
            It is all ready Now, Click Get IFRAME to get the Embidable Code  <br> 
          </h5>
            <div class="row mt-5">
              <div class="col-6 px-4">
                  <!-- <button class="w-100 btn btn-primary" @click='startApp'> Get IFRAME </button> -->
              </div>
              <div class="col-6 px-4">
                <!-- <button class="w-100 btn btn-danger" @click='$store.state.currentStep = null;app = null;'> View Services </button>               -->
              </div>
              <div class="col-12">
                <!-- <button class="btn btn-link" @click='loadDetails'>
                  Click to view all details
                </button> -->
              </div>
            </div>
          </div> 
    </div>      
  </div>
</template>

<script>
import Web3 from 'web3';
const web3 = new Web3(Web3.givenProvider);
import detectEthereumProvider from '@metamask/detect-provider';
import {mapState} from 'vuex';
import { parseBalanceMap } from '../parse-balance-map.ts'
export default {
data() {
    return {
      selectedAccount:null,
      currentStep:0,
      app:{},
        portal:null,
      stepStatus:['Initialized','Token & Chain Info Submitted','Submitted Distribution List','Contract Deployed','Dates Are Set'],
      slService:{
          _id:1,
          name:"Token Claim Service"
        },
      services:[
        {
          _id:1,
          name:"Token Claim Service"
        }
      ],
      chains:[
        {
          name:"Ethereum Main Network",
          chainId:"0x1",
          KEY:'3MBSDFSIQZ1B9WWYI1V7GY4MX9QFQE83XZ',
          API:"https://api.etherscan.io/"
        },
        {
          name:"Rinkeby Test Network",
          chainId:"0x4",
          KEY:'3MBSDFSIQZ1B9WWYI1V7GY4MX9QFQE83XZ',
          API:"https://api-rinkeby.etherscan.io/"
        },
        {
          name:"Binance Main Network",
          chainId:"0x38",
          KEY:'YU1IGKUWQDN49FP4T3YGV97VDV56HVG7AW',
          API:"https://api.bscscan.com/"
        },
        {
          name:"Binance Test Network",
          chainId:"0x61",
          KEY:'YU1IGKUWQDN49FP4T3YGV97VDV56HVG7AW',
          API:"https://api-testnet.bscscan.com/"
        }
      ],
      startDate:null,
      endDate:null,
      selectedChain:null,
      selectedService:{
          _id:1,
          name:"Token Claim Service"
        },
      showDetails:false,
      csvData:null,
      loading:false,
      mode:false,
      appSetup:false,
    fundAmount:0,
    walletCheckResults:null,
    vCheck:'',
    isAdmin:false,
    manualInput:false,
      changeAdmin:'',
      days:'',
      hours:'',
      web3:null,
      days:'',
      hours:'',
      minutes:'',
      seconds:'',
      countDownDate:new Date("Aug 23, 2022 4:14:25").getTime(),
      distance:null,
      now:null,
      expired:false,
      wAmount:0,
      fAmount:0,
      wAddress:null,
      wToken:null,
      loadingBQ:false,
      fileinput:null,
      errors:[],
      selectedToken:null,
      skipped:[],
      newTitle:"",
      isWalletValid:null,
      selectedSymbol:null,
      selectedDecimal:null,
      showWallets:false,
      provider:null,
      appTitle:"",
      validatedApp:true,
    }
},
computed:{
    ...mapState(["apps","user"]),
    totalClaimBalance(){
      if (this.app.merkleData) {
      let data = JSON.parse(this.app.merkleData)
      return (parseInt(data.tokenTotal,16)/10**this.app.DECIMALS+" "+this.app.symbol)
      }
    },
    totalClaimWallets(){
      if (this.app.merkleData) {
      let data = JSON.parse(this.app.merkleData)
      let count = Object.keys(data.claims);
      return count.length 
      }
    },
    claimWallets(){
      if (this.userAccount.merkleData) {
      let data = JSON.parse(this.userAccount.merkleData)
      return data.claims
      }
    },
    // currentStep(){
    //   if(this.app){
    //     return this.app.currentStep
    //   }else{
    //     return 1
    //   }
    // }
},
methods:{
    initApp(){
      // <button class="mt-1" @click="updateStepStatus(0);appSetup = true;app = null;"> Create Service </button>
      this.createApp = true;
    },
    async resetMerkleData(){
      let app = this.app;
      app.merkleData = "";
      this.app.merkleData = "";
      await this.$store.dispatch("updateAppData", {app});
      await this.$store.dispatch("getApps")      


    },
    async saveAppChanges(){
      if (this.isWalletValid) {
        if (this.selectedDecimal && this.selectedSymbol.length > 1 && this.selectedToken.length > 5 ) {
          this.app = {};
            this.app.service = this.selectedService;
            this.app.title = this.appTitle;
            this.app.DECIMALS = this.selectedDecimal;
            this.app.symbol = this.selectedSymbol;
            this.app.selectedChain = this.selectedChain;
            this.app.TOKEN = this.selectedToken;
            this.app.currentStep = this.currentStep+1;
            this.app.user = this.user._id;
            this.app.owner = this.selectedAccount;
            let dec = await this.$store.dispatch("createApp", {app: this.app})
            if (dec.status) {
    this.$store.dispatch("getApps")
                // this.app = dec.data;
            // this.$store.state.DECIMALS = this.selectedDecimal
            // this.$store.state.symbol = this.selectedSymbol
            // this.$store.state.currentStep = 1
            this.selectedToken = null;
            this.selectedSymbol = null;
            this.selectedDecimal = null;
            this.selectedChain = null;
            this.appTitle = '';
            this.currentStep++
            }else{
          alert("Failed to create Service, server error")
            }            
        }else{
          alert("invalid Symbol or Decimals")
        }
        
      }else{
        alert("Token Contract Address is not valid")
      }
    },
    async resetNetwork(){
      let dec = confirm("this will reset the Network, token and Distribution list if any generated and can't be recovered")
      if(dec){
        let app = this.app
        app.currentStep = 0
        app.selectedChain = {}
        app.DECIMALS = ""
        app.TOKEN = ""
        app.owner = ""
        app.symbol = ""
        app.CONTRACT = ""
        this.app = app
        await this.$store.dispatch("updateAppData",{app})
        await this.$store.dispatch("getApps")      
      }
    },
    async updateAppProps(prop){
      if(prop == 'title'){
        if(this.newTitle.length < 5){
          alert("Title must be at least 5 characters")
        }else{
         let dec = await this.$store.dispatch("updateProps",{id:this.app._id, [prop]: this.newTitle, prop}) 
         if(dec.status){
          alert(prop,' updated')
          this.app.title = this.newTitle
         }
        }
      
      }
    },
    publishApp(app){
      let dec = confirm("This will publish app publically")
      if(dec){
        this.$store.dispatch("publishApp",{app: app._id})
      }
    },
    logout(){
      this.$store.dispatch("logout");
      this.$router.push({path:"/"})
    },
    loadApp(app){
      let link = location.origin
      link += '/app/'+app._id
      window.open(link)
    },
    CtotalClaimBalance(app){
      if (app.merkleData) {
      let data = JSON.parse(app.merkleData)
      return (parseInt(data.tokenTotal,16)/10**app.DECIMALS+" "+app.symbol)
      }
    },
    CtotalClaimWallets(app){
      if (app.merkleData) {
      let data = JSON.parse(app.merkleData)
      let count = Object.keys(data.claims);
      return count.length 
      }
    },
    
    backToApps(){
      this.app = null;
      this.appSetup = false;
      this.$store.state.currentStep = null;
    },
    loadSetup(app){
this.app = app;
this.$store.state.DECIMALS = this.app.DECIMALS;
this.$store.state.symbol = this.app.symbol;
this.updateStepStatus(this.app.currentStep);
this.appSetup = true;
    },
    updateStepStatus(step){
        this.$store.state.currentStep = step;
        if(step == 0) {
          this.newTitle = (this.app) ? this.app.title : ""
        }
    },
    stepBack(step){
        if (step == 0) {
        this.$store.state.registeredWallets[this.selectedAccount].claims[this.appId].DECIMALS = null
        this.$store.state.registeredWallets[this.selectedAccount].claims[this.appId].symbol = null
        this.$store.state.registeredWallets[this.selectedAccount].claims[this.appId].TOKEN = null
        this.$store.state.registeredWallets[this.selectedAccount].claims[this.appId].selectedChain = null
        }
      this.$store.dispatch("stepBack", {step, id:this.appId})

    },
    resetApp(){
      let dec = confirm("Are you sure? this will cause wipe of all current data plus loss of deployed smart contract")
      if (dec) {
        this.$store.dispatch("resetApp", this.appId)
      }
    },
    goToScan(CONTRACT,chainId){
      let network = '';
       if (chainId == "0x1") network = "https://etherscan.io/address/"
      if (chainId == "0x4") network = "https://rinkeby.etherscan.io/address/"
      if (chainId == "0x38") network = "https://bscscan.com/address/"
      if (chainId == "0x61") network = "https://testnet.bscscan.com/address/"
      window.open(network+CONTRACT, '_blank').focus();

    },
    async checkParams(){
      let params = this.$route.params;
      this.appId = params.id
      this.portal = params.location
      if (this.portal ==  "portal") {
        this.connect()
      }
    let dec = await this.$store.dispatch("loadAppConfig", params)
    if (dec) 
    {
      this.validatedApp = true
      if (this.startTime && this.endTime) {
        await this.timer();        
      }

    }
    else this.validatedApp = false

    },
    startApp(){
      this.$store.dispatch("startApp", this.selectedAccount)
    },
    loadDetails(){
        this.$bvModal.show("all-details")
    },
    async saveTimeChanges(){
      //check if end date is null or greater then start date 
      
      if (this.endDate && this.startDate) {
        let edr = false;
        let sdr = false;
        var d1 = new Date(this.startDate);
        var d2 = new Date(this.endDate);
        if (d2 > d1) {
          this.app.endTime = this.endDate
        //   this.$store.state.registeredWallets[this.selectedAccount].claims[this.appId].endTime = this.endDate
          edr = true
        }else{
          alert("End Date must be greater then Start Date")
        }
        //
        var d1 = new Date(this.startDate);
          var d2 = new Date();
          if (d1 > d2) {
          this.app.startTime = this.startDate
        //   this.$store.state.registeredWallets[this.selectedAccount].claims[this.appId].startTime = this.startDate
            sdr = true
          }else{
            alert("Start Date must be a future date")
          }

          if (sdr && edr) {
          this.app.currentStep = 4
      let dec = await this.$store.dispatch("updateAppData", {app:this.app, next: 4})

        //   this.$store.state.currentStep = 4
        //   this.$store.dispatch("updateAccounts")
            // alert("Date are configured now")
          }else{
            alert("Dates are not configured correctly, please check dates")
          }
      }
      else{
        alert("Please provide valid date")
      }
      // var same = d1.getTime() === d2.getTime();
      // var notSame = d1.getTime() !== d2.getTime();
    },
    async confirmDeploy(){
        if (this.selectedAccount) {
      const provider = await detectEthereumProvider();
            if (provider) {
        this.web3 = new Web3(provider);
        provider.enable();
        const chainId = await provider.request({
            method: 'eth_chainId'
          })
       if (chainId == this.app.selectedChain.chainId) {
        this.$store.dispatch("deployContract", {app:this.app, account:this.selectedAccount})
      }else{
        alert("Please change meta mask network to your Selected "+this.app.selectedChain.name+" and then retry")
      }   
      }
        }else{
            alert("Wallet Connectivity Required")
        }
         
          
    },
    async validateWallet(){
      this.loadingBQ = true;
      if (web3.utils.isAddress(this.selectedToken)) {
        console.log("passed test wallet")
        // let result = await fetch(this.selectedChain.API+'api?module=contract&action=getabi&address='+this.selectedToken+'&apikey='+this.selectedChain.KEY)
        // result = await result.json()
        let result = await this.$store.dispatch("BquerySymbolDecimals",{chain:this.selectedChain.chainId,address:this.selectedToken})
        console.log("result of BquerySymbolDecimals:",result)
        if (result) {
          this.loadingBQ = false
        this.isWalletValid = true
        this.selectedSymbol = result[0].smartContract.currency.symbol
        this.selectedDecimal = result[0].smartContract.currency.decimals
        }else{
          this.loadingBQ = false

          this.isWalletValid = true
        this.manualInput = true
        }
        // if (result.status !== '0') {
        // let contractABI = JSON.parse(result.result);
        // console.log(contractABI)  
        // }else{
        //   alert(result.result)
        
        // }
        
        return
        this.$store.dispatch("loadSymbolForm", {address:this.selectedToken, chain:this.selectedChain})
      } else {
        console.log("uinvalid wallet")
        this.loadingBQ = false

        this.isWalletValid = false
      }
    },
    async saveTokenChanges(){
      if (this.isWalletValid) {
        if (this.selectedDecimal && this.selectedSymbol.length > 1 && this.selectedToken.length > 5 && this.appTitle.length > 3 ) {
          this.app = {};
            this.app.service = this.selectedService;
            this.app.title = this.appTitle;
            this.app.DECIMALS = this.selectedDecimal;
            this.app.symbol = this.selectedSymbol;
            this.app.selectedChain = this.selectedChain;
            this.app.TOKEN = this.selectedToken;
            this.app.currentStep = this.currentStep+1;
            this.app.user = this.user._id;
            this.app.owner = this.selectedAccount;
            let dec = await this.$store.dispatch("createApp", {app: this.app})
            if (dec.status) {
    this.$store.dispatch("getApps")
                this.app = dec.data;
            this.$store.state.DECIMALS = this.selectedDecimal
            this.$store.state.symbol = this.selectedSymbol
            this.$store.state.currentStep = 1
            this.selectedToken = null;
            this.selectedSymbol = null;
            this.selectedDecimal = null;
            this.selectedChain = null;
            this.appTitle = '';
            }else{
          alert("Failed to create Service, server error")
            }            
        }else{
          alert("invalid Symbol or Decimals")
        }
        
      }else{
        alert("Token Contract Address is not valid")
      }
    },
    async handleFile(e){
      var files = e.target.files || e.dataTransfer.files;
      if (files.length){
        await this.createInput(files[0]);
        
      }else{
        console.log("Invalid file")
      }
        
    },
     async createInput(file) {
      let fileinput = null
                var reader = new FileReader();
                var vm = this;
                reader.onload = async (e) => {

                fileinput = reader.result;
                this.fileinput = fileinput
                let config = {}
                let data = []
                try {
                data = this.$papa.parse(this.fileinput,[config]);
                  
                } catch (error) {
                alert("Invalid CSV file Format")  
                }
                data = data.data
                this.csvData = {}
                this.errors = [];
                this.skipped = [];
                data.forEach((element, index) => {
                  if (element.length == 2) {
                    let val = Math.round((Number(element[1])*10**this.app.DECIMALS)).toString(16)
                    let dc = val.split(".")
                    // console.log("dc is", dc)

                    if (  isNaN(  Number(element[1])  )  ) {
                      let message = `Wallet ${element[0]} Does not Contains A Valid Amount, Entry Will Be Skipped, on Line ${index+1}`
                      this.errors.push(message)
                      this.skipped.push(element[0])
                    }
                    
                    else if (this.csvData[String(element[0])]) {
                      let message = `Wallet ${String(element[0])} Was found Duplicate, Entry Will Be Skipped on Line ${index+1}`
                      this.errors.push(message)
                      this.skipped.push(element[0])
                    }

                    else if (!web3.utils.isAddress(element[0])) {
                      let message = `Wallet Address ${element[0]} Was Found As Invalid Wallet Address, Entry Will Be Skipped on Line ${index+1}`
                      this.errors.push(message)
                      this.skipped.push(element[0])
                    }else{
                        this.csvData[String(element[0])] = dc[0]
                    }

                  }
                });
                this.$bvModal.show("import-result")
                }
                reader.readAsText(file);
            },
    loadSampleImage(){
      this.$bvModal.show("sample-image")
    },
    async generateRoot(){
console.log(this.csvData)
      try {
      let data = JSON.stringify(parseBalanceMap(this.csvData))
      this.app.merkleData = data;
      this.app.currentStep = 2;
      let dec = await this.$store.dispatch("updateAppData", {app:this.app})
      if (!dec.status) {
        alert("Failed to Generate the List, Server error")
      }else{
        this.currentStep++
      }
      } catch (error) {
        console.log(error)
        alert("Failed to Generate the List, Compliation error")
      }
    },
    setDays(value) { this.days = value},
    setHours(value) { this.hours = value},
    setMinutes(value) { this.minutes = value},
    setSeconds(value) { this.seconds = value},
    timer() {
      var x = setInterval(()=>{

      // Get today's date and time
      this.now = new Date().getTime();
        
      // Find the distance between now and the count down date
      let countDownDate = new Date(this.endTime).getTime()
      this.distance = countDownDate - this.now;
        
      // Time calculations for days, hours, minutes and seconds
      this.setDays(Math.floor(this.distance / (1000 * 60 * 60 * 24)));
      this.setHours(Math.floor((this.distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
      this.setMinutes(Math.floor((this.distance % (1000 * 60 * 60)) / (1000 * 60)));
      this.setSeconds(Math.floor((this.distance % (1000 * 60)) / 1000));
          
      // count down is over 
      if (this.distance < 0) {
        clearInterval(x);
        // alert("EXPIRED");
        this.expired = true;
      }
    }, 1000);
    },
    copy(text){
  var copyText = text

  navigator.clipboard.writeText(copyText);

  alert("Copied the text: " + copyText);
  

    },
    goTo(){
      window.open("https://claimelly.com", "_blank")
    },
    goTos(){
      window.open("https://hbsc-website-test-qelxg.ondigitalocean.app", "_blank")
    },
    runBquery(){
      this.$store.dispatch("Bquery");
    },
    async checkValue(){
      let val = Web3.utils.toChecksumAddress( this.vCheck ) ;
      let result = this.claimData.claims[ val ];
      if (result !== undefined) {
        let dec = await this.$store.dispatch("checkClaimStatus", val)
        let status = (dec) ? "Claimed" : "UnClaimed"
        console.log(result, dec)
        let amt = result.amount
        amt = (Number(amt) / this.DECIMALS)
        let message = "the wallet "+val+" has a Claim of "+amt+" "+this.symbol+"  and claim status is "+status+""
        alert(message)
      }else{
        let message = ' This wallet is not eligible for Claim'
        alert(message)
      }
      console.log(this.claimData.claims)
    },
    async withdrawNative(){
      this.$store.dispatch("withdrawNative",{sender: this.selectedAccount})
    },
    async withdraw() {
      console.log("running withdraw function")
      if(this.wAmount > 0 && this.wToken.length > 5){
        console.log(this.wAmount)
      let dec = await this.$store.dispatch('withdraw', {
        sender: this.selectedAccount,
        amount: this.wAmount,
        token: this.wToken
      })
      if (dec) {
          this.$store.dispatch('Bquery');
        // await this.$store.dispatch('BalanceCheck',  this.selectedAccount) ;
          this.wAmount = 0
          this.wToken = ''
      }
      }else{
        alert('please provide valid value and address')
      }
    },
      async withdrawAll(){
      let admin = this.admin
      let claimAccountBalance = this.claimAccountBalance
      let symbol = this.symbol
      let message = "Are you sure you want to withdraw "+claimAccountBalance+symbol+" to wallet "+admin
      console.log(message, admin, claimAccountBalance, symbol)
      let d = confirm(message)
      if (d) {
        let dec = await this.$store.dispatch("withdraw", {sender: this.selectedAccount, amount:claimAccountBalance})
      if (dec) {
        await this.$store.dispatch('contractBalance');
        await this.$store.dispatch('BalanceCheck',  this.selectedAccount) ;

      }  
      }
      
    },
    async updateAdmin(){
      await this.$store.dispatch("updateAdmin", {wallet:this.changeAdmin, sender: this.selectedAccount})
      this.$store.dispatch('getOwner');
       this.$store.dispatch('getAdmin');
       this.changeAdmin = '' 
    },
   
     onComplete(data) {
      this.$store.state.selectedAccount = data.metaMaskAddress
      console.log(data)
    },
    async fund() {
      await this.$store.dispatch('fundContract',{amount:this.fAmount})
      this.fAmount = 0
      await this.$store.dispatch("contractBalance");
    },
    async setProvider() {
      this.$store.state.provider = await detectEthereumProvider();
      if (this.$store.state.provider) {
        this.web3 = new Web3(this.$store.state.provider);
        this.$store.state.provider.enable();
        const chainId = await this.$store.state.provider.request({
            method: 'eth_chainId'
          })
        this.$store.state.provider.enable();

        console.log('chainID', chainId);
        window.ethereum.on('chainChanged', () => {
          this.connect()
        })
        window.ethereum.on('accountsChanged', () => {
          // window.location.reload();
          this.connect()
        })
        
        // 0x38 for mainnet
        // 0x61 for mainnet
        // if (chainId !== "0x38") {
        //   try {
        //   await window.ethereum.request({
        //     method: 'wallet_switchEthereumChain',
        //     params: [{ chainId: '0x38' }], // chainId must be in hexadecimal numbers
        //   });  
        //   } catch (error) {
        //     console.log("Error in changing chain", error)
        //     alert("Please Add BSC network in Meta Mask")
        //   }
          
        //   return false;
        // }
        this.$store.state.provider.enable();
        return true
      } else {
        console.log('Please install MetaMask!');
        return false;
      }
    },
    async connect(){
      let dec = await this.setProvider(); 
      console.log('set ptovidder result', dec)
      if (dec) {
        let user = null;
          let addresses = await window.ethereum.request({method: "eth_requestAccounts",});
              if(addresses.length>0){
                await web3.eth.getAccounts((error,result) => {
                  console.log(error, result)
                    if (error || result.length < 1) {
                        console.log(error, 'or no accounts');
                    } else {
                      console.log('setting up user account',result[0])
                      this.selectedAccount = result[0]
                    // this.app.owner = result[0]
                    }
                }).then(async () => {
                    return

                  if(user){
                    console.log('found user', user)
                    this.$store.state.selectedAccount = user
                    this.app.owner = user
                    if (this.setupComplete) {
                      const chainId = await this.$store.state.provider.request({
                        method: 'eth_chainId'
                      })
                    if (chainId !== this.userAccount.selectedChain.chainId) {
                      try {
                      await window.ethereum.request({
                        method: 'wallet_switchEthereumChain',
                        params: [{ chainId: this.userAccount.selectedChain.chainId}],
                      });

                    // this.$store.dispatch("loadSymbol", this.appId)
                    // this.$store.dispatch('getOwner');
                    // this.$store.dispatch('getAdmin');
                    // this.$store.dispatch('contractBalance');
                    this.$store.dispatch('Bquery');
                    // this.$store.dispatch('BalanceCheck', user); 

                      } catch (error) {
                        console.log("Error in changing chain", error)
                        alert("Please Add "+this.userAccount.selectedChain.name+" in Meta Mask")
                      }
                      
                      return false;
                    }else{
                    this.$store.dispatch("loadSymbol", this.appId)
                    this.$store.dispatch('getOwner');
                    this.$store.dispatch('getAdmin');
                    // this.$store.dispatch('contractBalance');
                    this.$store.dispatch('Bquery');
                    // this.$store.dispatch('BalanceCheck', user); 
                    }
                     
                    }else{
                      this.registrationProcess() 
                    }
                  }else{
                    console.log(user,'user not found')
                  }
                })
                }
        }  
      
    },
    async registrationProcess(){
      // check wallet is in registered list
      // check how much steps completed
      // start the step required to run
      let registered = await this.$store.dispatch("checkWalletAccount", this.appId)
      if (registered) {
        
      }else{
        alert("Account Not Found")
      }
    },
    async claim(value) {
      if(window.ethereum)
      {
        let amt = value
        let dec = await this.$store.dispatch("checkClaimStatus", this.selectedAccount)
        console.log("result of claim status check is", dec)
        if (!dec) {
            let de = await this.$store.dispatch('hasClaimed', {account:this.selectedAccount,value:amt} )
            console.log('transaction result', de)    
        } else {
          alert("Already Claimed")
        }
      
      }else{
        console.log('not found meta mask')
        alert("Install Metamask")
      }
    },
    
   
  }
}
</script>

<style>

</style>